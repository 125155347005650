
import { validRoleName } from '@/utils/utils'
import http from '@/utils/http'
import { apiGetRoles, AuthItem } from '@/api/role'
import G from '@/config/config'
import { apiGetAuth } from '@/api/user'
import { PageList } from '@/vo/PageList'
import { UserInfo } from '@/vo/UserInfo'
import { ElForm, ElMessage, ElMessageBox, ElTabPane } from 'element-plus'
import { defineComponent } from 'vue'

interface TreeAuthItem extends AuthItem {
  label: string
  id: number
  children: TreeAuthItem[]
  subAuth: TreeAuthItem[]
}
export default defineComponent({
  name: 'Roles',
  data() {
    const validateRoleName = (rule: unknown, value: string, callback: Function) => {
      if (!validRoleName(value)) {
        callback(new Error('角色名称格式不正确：最长40位，支持中文字符'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      nameEdit: {} as {
        [index: number]: boolean
      },
      info: {},
      roleName: '',
      createVisible: false,
      role: {
        name: '',
        platform: G.platform,
      },
      formRules: {
        name: [{ required: true, trigger: 'blur', validator: validateRoleName }],
      },
      editVisible: false,
      activeName: 'permission',
      defaultExpanded: Array<number>(),
      defaultChecked: Array<number>(),
      selectedAuth: Array<number>(),
      currentRoleId: -1,
      changeVisible: false,
      user: {
        roleId: -1,
        uid: -1,
      },
      catchRequest: false,
      allRoles: Array<AuthItem>(),
      allAuth: Array<TreeAuthItem>(),
      users: Array<UserInfo>(),
      pageSize: 10,
      pageNo: 1,
    }
  },

  created() {
    this.init()
    this.getAllUsers()
  },
  methods: {
    getAllUsers() {
      return http<PageList<UserInfo>>('GET', `/correct/bs/userdomain/user/query/all`, {
        platform: G.platform,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then(resp => {
        this.info = resp
        this.users = resp.data
      })
    },
    init() {
      this.loading = true
      return apiGetRoles()
        .then(resp => {
          this.allRoles = resp
        })
        .finally(() => {
          this.loading = false
        })
    },
    create() {
      this.createVisible = true
    },
    createCancel() {
      this.role.name = ''
      this.$nextTick(() => {
        this.createVisible = false
      })
    },
    createConfirm() {
      this.role.platform = G.platform
      let refForm = this.$refs.form as InstanceType<typeof ElForm>
      refForm.validate(valid => {
        if (valid) {
          this.catchRequest = true

          http('POST', `/correct/bs/userdomain/role/create`, this.role)
            .then(() => {
              this.createVisible = false
              ElMessage.success('新建成功！')
              this.init()
            })
            .finally(() => {
              this.catchRequest = false
            })
        } else {
          return false
        }
      })
    },
    editName(roleId: number, roleName: string) {
      this.roleName = roleName
      for (let i in this.nameEdit) {
        this.nameEdit[i] = false
      }
      this.nameEdit[roleId] = true
    },
    editNameConfirm(roleId: number) {
      if (!this.roleName) {
        ElMessage.error('角色名不能为空')
        return false
      }
      if (this.roleName.length > 40) {
        ElMessage.error('角色名不能超过40个字符')
        return false
      }

      http('POST', `/correct/bs/userdomain/role/update`, {
        platform: G.platform,
        roleId,
        name: this.roleName,
      }).then(() => {
        ElMessage.success('改名成功')
        this.init().then(() => {
          this.nameEdit[roleId] = false
        })
      })
    },
    transformAuth(arr: Array<AuthItem>): Array<TreeAuthItem> {
      const getStr = (list: Array<TreeAuthItem>) => {
        list.forEach(row => {
          row.label = row.authName
          row.id = row.authId
          if (row.subAuth && row.subAuth.length) {
            row.children = row.subAuth
            getStr(row.subAuth)
          } else {
            return false
          }
        })
      }
      getStr(arr as Array<TreeAuthItem>)
      return arr as Array<TreeAuthItem>
    },
    async getAuth(roleId: number) {
      let auths = await apiGetAuth()
      this.allAuth = this.transformAuth(auths)
      return http<AuthItem[]>('GET', `/correct/bs/userdomain/role/auth/query`, {
        platform: G.platform,
        roleId,
      }).then(resp => {
        return this.transformAuth(resp)
      })
    },
    recursive(arr: Array<TreeAuthItem>) {
      const getStr = (list: Array<TreeAuthItem>) => {
        list.forEach(row => {
          if (row.subAuth && row.subAuth.length) {
            this.defaultExpanded.push(row.id)
            this.selectedAuth.push(row.id)
            getStr(row.subAuth)
          } else {
            this.defaultChecked.push(row.id)
            this.selectedAuth.push(row.id)
            return false
          }
        })
      }
      getStr(arr)
    },
    edit(roleId: number) {
      this.currentRoleId = roleId
      this.getAuth(roleId)
        .then(res => {
          this.recursive(res)
        })
        .then(() => {
          this.activeName = 'permission'
          this.editVisible = true
        })
    },
    getParentsById(list: Array<TreeAuthItem> | undefined, id: number): Array<TreeAuthItem> | undefined {
      if (list !== void 0) {
        for (let i in list) {
          if (list[i].id === id) {
            return [list[i]]
          }
          if (list[i].children) {
            let node: Array<TreeAuthItem> | undefined = this.getParentsById(list[i].children, id)
            if (node !== undefined) {
              return node.concat(list[i])
            }
          }
        }
      }
      return undefined
    },
    getNode(list: Array<TreeAuthItem>, id: number): TreeAuthItem | undefined {
      if (Object.prototype.toString.call(list) !== '[object Array]') {
        return undefined
      }
      for (let i in list) {
        let item = list[i]
        if (item.id === id) {
          return item
        } else {
          if (item.children) {
            let value = this.getNode(item.children, id)
            if (value) {
              return value
            }
          }
        }
      }
    },
    selectAuth(node: TreeAuthItem, checkedKeys: { checkedKeys: number[] }) {
      let arr = checkedKeys.checkedKeys
      const isChecked = arr.indexOf(node.id) !== -1
      arr.forEach(v => {
        let pNode = this.getParentsById(this.allAuth, v)
        const parentId = pNode ? (pNode[1] ? pNode[1].id : undefined) : undefined
        if (isChecked) {
          if (parentId && arr.indexOf(parentId) === -1) {
            arr.push(parentId)
          }
        } else {
          if (parentId) {
            /*
              如果点击的是内部节点，需要判断兄弟节点是否全部删除，如果还有兄弟节点就把父节点再加上
              ps: element.ui “el-tree”当你取消选中时会自动将其父节点删除
            */
            const parentNode = this.getNode(this.allAuth, parentId)
            let isAllDelete = true
            if (parentNode) {
              parentNode.children.forEach(item => {
                const index = arr.findIndex(value => value === item.id)
                if (index !== -1) {
                  isAllDelete = false
                }
              })
              if (!isAllDelete && arr.indexOf(parentNode.id) === -1) {
                arr.push(parentNode.id)
              }
            }
          }
        }
      })
      this.selectedAuth = arr
    },
    selectedAuthConfirm() {
      http('POST', `/correct/bs/userdomain/role/appoint/auth`, {
        platform: G.platform,
        roleId: this.currentRoleId,
        authIdList: this.selectedAuth,
      }).then(() => {
        ElMessage({
          type: 'success',
          message: '权限修改成功',
          duration: 1500,
          onClose: () => {
            this.editVisible = false
            window.location.reload()
          },
        })
      })
    },
    change(uid: number, roleId: number) {
      this.changeVisible = true
      this.user.roleId = roleId
      this.user.uid = uid
    },
    changeCancel() {
      this.user.roleId = -1
      this.user.uid = -1
      this.changeVisible = false
    },
    changeConfirm() {
      http('POST', `/correct/bs/userdomain/role/update/user`, {
        uid: this.user.uid,
        roleId: this.user.roleId,
        platform: G.platform,
      }).then(() => {
        ElMessage.success('更换成功')
        this.getAllUsers().then(() => {
          this.changeVisible = false
        })
      })
    },
    handleCurrentChange(pageNo: number) {
      this.pageNo = pageNo
      this.getAllUsers()
    },
    handleSizeChange(pageSize: number) {
      this.pageSize = pageSize
      if (this.pageNo != 1) {
        this.pageNo = 1
      } else {
        this.$nextTick(() => {
          this.getAllUsers()
        })
      }
    },
    editCancel() {
      this.defaultExpanded = []
      this.defaultChecked = []
      this.selectedAuth = []
      this.editVisible = false
    },
    handleClick(tab: InstanceType<typeof ElTabPane>) {
      this.activeName = tab.paneName
    },
    del(roleId: number) {
      ElMessageBox.confirm('确认删除角色吗？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        http('POST', `/correct/bs/userdomain/role/delete?roleId=${roleId}`, {
          roleId,
        }).then(() => {
          ElMessage.success('删除成功！')
          this.init()
        })
      })
    },
  },
})
